.chevronIcon {
  font-size: 32px;
  font-weight: bold;
  color: white;
}

.chevronIconSmall {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.chevronButton {
  background-color: transparent;
  border-width: 0px;
  height: 100%;
}

.chevronButton:hover {
  background-color: transparent;
  color: white;
}

.cardTitle {
  color: white;
  font-size: 18px;
  line-height: 22px;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
  font-weight: bold;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 8px;
}
.cardDescription {
  color: white;
  font-size: 16px;
  text-align: center;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
}
.deleteText{
  text-decoration:line-through;
  margin-right: 5px;
  color: #999999;
}


/* .imagecard{
  padding: 0px 10px;
} */
.img_div {
  position: absolute;
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  height:0;
  /* height: 20vw; */
  /* width: 234px;
  height: 234px; */
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 234px;
  height: 234px; */
  width: 100%;
  padding-bottom: 100%;
  height:0;
  /* height: 20vw; */
  background: transparent;
  display: none;
  z-index: 1;
}

.img_div img {
  position: absolute;
  transition: all 0.4s;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  vertical-align: middle;
}

.img_div:hover .mask {
  display: block;
}
.mask:hover + img {
  transform: scale(1.3);
}

.category-card {
  width: 511px;
  height: 681px;
  margin: auto 30px;
}
.category {
  font-weight: bold;
  font-family: "roboto-thin", "roboto", sans-serif;
  font-size: 22px;
  margin: 10px auto 10px auto;
  color: white;
}

.category_img_div {
  margin: 0px auto;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border-radius: 30px 30px 30px 30px;
}

.category_mask {
  border-radius: 30px 30px 30px 30px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  display: none;
  z-index: 1;
}

.category_img_div img {
  transition: all 0.4s;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.category_img_div:hover .category_mask {
  display: block;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(136, 88, 237, 0.35));
}
.category_mask:hover + img {
  transform: scale(1.3);
}
