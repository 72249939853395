.headmenu {
  /* background-image: url('/public/imgs/nav_bg.jpg'); */
}


.logo {
    float: left;
    /* width: 180px; */
    padding:0;
    margin:0;
    /* height: 100%; */
    /* margin: 16px 24px 16px 0;     */
    /* background: rgba(255, 255, 255, 0.3);  */
    display: table-cell;
    vertical-align: middle;
    text-align: left;
    /* background: transparent url('/public/imgs/logo.png') no-repeat; */
    /* background-size: auto 50px; */
  }
.logoDiv {
  width: auto;
  height: 100%;
}
.logoDiv img {
  width: 238px;
  height: 72px;
  object-fit: cover;
}

.menuDiv {
  /* flex-grow: 1; */
  /* float: right; */
  
  /* position: absolute; 
  right: 100px; */
  /* display: flex;
    flex-direction: row nowrap;
    justify-content: flex-end; */
    width: 700px;
}


/*适应PC端 宽度大于1000px*/
@media screen and (min-width: 1000px) {
  .webname {
    /* display: inline-block; */
    /* color:antiquewhite;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 24px; */
    font-family: roboto-thin,roboto,sans-serif,montserrat; 
    font-size:22px;
    font-weight:bold;
    color: white;
    padding:0;
    margin: 0 0 0 10px;
  }
  .logoImg {
    /* display: inline-block; */  
    width:auto;
    /* max-width:100%; */
    max-height:50px;
    height:50px;    
  }
}
/*适应pad端 宽度在640-1000之间*/
@media screen and (min-width: 640px) and (max-width: 1000px) {}
/*适应移动端 宽度小于640*/
@media screen and (max-width: 1000px){
  .webname {
    /* display: inline-block; */
    /* color:antiquewhite;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bold;
    font-size: 24px; */
    font-family: roboto-thin,roboto,sans-serif,montserrat; 
    font-size:18px;
    font-weight:bold;
    color: white;
    padding:0;
    margin: 0 0 0 10px;
  }
  .logoImg {
    /* display: inline-block; */  
    width:auto;
    /* max-width:100%; */
    max-height:40px;
    height:40px;    
  }
}


/* .head-menu {
    float: center;
} */