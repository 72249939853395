.headerextended {
  background-image: url("/public/imgs/nav_bg.webp");
  background-size: cover;
  height: 100px;
  width: 100%;
  padding-top: 20px;
}

.vlayout {
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: stretch;
  /* position: relative; */
  width: 100%;
}
.vlayout_center {
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  /* position: relative; */
  width: 100%;
}
.vlayout_end {
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-end;
  /* position: relative; */
  width: 100%;
}
.vlayout_start {
  display: -webkit-flex;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  /* position: relative; */
  width: 100%;
}

.hlayout {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.hlayout_center {
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.bg {
  width: 100%;
  background-color: black;
  color: white;
}
.bgBlack {
  width: 100%;
  background-color: black;
}
.bgGray {
  width: 100%;
  background-color: rgba(77, 77, 77, 0.4);
}
.bgImage1 {
  width: 100%;
  background-image: url("/public/imgs/bg_3.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.bgImage2 {
  width: 100%;
  background-image: url("/public/imgs/bg_4.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.bgImage3 {
  width: 100%;
  background-image: url("/public/imgs/bg_5.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
.content {
  width: 80%;
  margin: 0 auto;
  padding: 10px 0px 60px 0px;
  background-color: transparent;
}
/* 居中屏幕 */
.centerScreen {
  width: auto;
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
}
.detailcontent {
  width: 60%;
  margin: 40px auto;
  padding: 10px 0px 60px 0px;
  background-color: transparent;
}
.currentItem {
  color: rgba(136, 88, 237, 1);
}
.part1 {
  background-image: url("/public/imgs/bg_1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
}
.part1_main {
  color: rgba(6, 5, 6, 0.65);
  width: 100%;
  margin: 0 auto -148px auto;
}
.part1_main_col1 {
  width: 580px;
  border-radius: 30px 30px 30px 30px;
  margin: 82px auto 0px auto;
  padding: 34px 58px;
  background-color: rgba(136, 88, 237, 1);
  box-shadow: 4.23px 9.06px 14px 6px rgba(6, 5, 6, 0.41), 0 1px 4px rgba(0, 0, 0, 0.6);
}
.part1_main_col1_item1 {
  font-size: 22px;
  font-weight: bold;
  font-family: "roboto-thin", "roboto", sans-serif;
  color: white;
  margin-bottom: 15px;
}
.part1_main_col1_item2 {
  font-size: 70px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  line-height: 70px;
  color: white;
  margin-bottom: 11px;
}
.part1_main_col1_item3 {
  font-size: 22px;
  font-weight: bold;
  font-family: "roboto-thin", "roboto", sans-serif;
  color: white;
  margin-bottom: 24px;
}
.normalButton {
  font-size: 15px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  text-decoration: rgb(0, 0, 238);
  width: 143px;
  height: 48px;
  border-radius: 100px 100px 100px 100px;
  color: #8858ed;
  border-color: white;
  border-width: 2px;
  border-style: solid;
}
.normalButton:hover {
  color: white;
  background-color: #8858ed;
}
.buyButton {
  font-size: 15px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  min-width: 134px;
  height: 38px;
  border-radius: 100px 100px 100px 100px;
  color: white;
  background-color: #8858ed;
  border-width: 0px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.buyButton:hover {
  background-color: #62479d;
  color: rgb(188, 183, 183);
}
.buyButton_2 {
  font-size: 15px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  min-width: 134px;
  height: 38px;
  border-radius: 100px 100px 100px 100px;
  color: #8858ed;
  background-color: white;
  border-width: 0px;
  margin: 0 auto;
  margin-bottom: 10px;
}
.buyButton_2:hover {
  background-color: white;
  color: #8858ed;
}
.part1_main_col2 {
  width: 440px;
  /* height: 689px; */
  border-radius: 30px 30px 30px 30px;
  margin-top: 82px;
  margin-left: auto;
  margin-right: auto;
  padding: 0px;
  background-color: rgba(6, 5, 6, 1);
  box-shadow: 2.27px 4.46px 15px 5px rgba(34, 33, 34, 0.6), 0 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.part1_bottom {
  display: block;
  position: static;
  min-height: 110px;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
}
.title {
  padding-top: 50px;
  padding-bottom: 20px;
}
.categoryTitle {
  font-family: "montserrat", sans-serif;
  font-size: 42px;
  font-weight: bold;
  color: white;
}
.Title {
  font-family: "montserrat", sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: white;
  text-align: center;
}
.produclist {
  margin: 25px auto 50px auto;
}

.part4_col1 {
  width: 557px;
  border-radius: 30px 30px 30px 30px;
  margin-top: 84px;
  margin-left: 211px;
  padding: 34px 58px;
  background-color: rgba(136, 88, 237, 1);
  /* box-shadow: 2.27px 4.46px 15px 5px rgba(34,33,34,0.6), 0 1px 3px rgba(0,0,0,.5); */
  box-shadow: 4.23px 9.06px 14px 6px rgba(6, 5, 6, 0.41), 0 1px 4px rgba(0, 0, 0, 0.6);
  z-index: 10;
}
.part4_col1_item1 {
  font-size: 22px;
  font-weight: bold;
  font-family: "roboto-thin", "roboto", sans-serif;
  margin: 10px auto;
  color: white;
}
.part4_col1_item2 {
  font-size: 140px;
  line-height: 98px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  margin: 10px auto;
  color: white;
}
.part4_col1_item3 {
  font-size: 42px;
  line-height: 58px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  margin: 10px auto 20px auto;
  color: white;
}

.cover_img {
  width: 100%;
  height: 100%;
  border-radius: 30px 30px 30px 30px;
  object-fit: cover;
}
.part4_col2 {
  width: 383px;
  height: 519px;
  border-radius: 30px 30px 30px 30px;
  margin-top: 52px;
  margin-left: -200px;
  padding: 0px;
  /* background-color: rgba(6,5,6,1); */
  box-shadow: 2.27px 4.46px 15px 5px rgba(34, 33, 34, 0.6), 0 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 7;
  flex-shrink: 0;
  display: block;
}
.part4_col3 {
  width: 384px;
  height: 523px;
  border-radius: 30px 30px 30px 30px;
  margin-top: 185px;
  margin-left: -20px;
  padding: 0px;
  /* background-color: rgba(6,5,6,1); */
  box-shadow: 2.27px 4.46px 15px 5px rgba(34, 33, 34, 0.6), 0 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 8;
  flex-shrink: 0;
  display: block;
}
.part4_col4 {
  width: 388px;
  height: 523px;
  border-radius: 30px 30px 30px 30px;
  margin-top: 109px;
  margin-left: -100px;
  padding: 0px;
  /* background-color: rgba(6,5,6,1); */
  box-shadow: 2.27px 4.46px 15px 5px rgba(34, 33, 34, 0.6), 0 1px 3px rgba(0, 0, 0, 0.5);
  z-index: 9;
  flex-shrink: 0;
  display: block;
}
.part6_item1 {
  font-size: 70px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  line-height: 70px;
  color: white;
  margin-top: 160px;
  margin-bottom: 11px;
}
.part6_item2 {
  font-size: 22px;
  font-weight: bold;
  font-family: "roboto-thin", "roboto", sans-serif;
  color: white;
  margin-bottom: 40px;
}
.formlabel {
  font-size: 16px;
  width: 283px;
  text-align: center;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
  color: white;
  margin-bottom: 40px;
}
.emailForm {
  width: 512px;
  padding-bottom: 120px;
}
.emailInput {
  height: 51px;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  background-color: transparent;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
  font-size: 16px;
  color: white;
  line-height: 22.4px;
  padding: 3px;
  text-align: center;
  border-width: 0px;
  border-width: 2px;
  border-style: solid;
  border-color: white;
}
.emailInput:focus {
  border-color: rgba(255, 60, 0, 1);
  border-width: 3px;
  border-style: solid;
  outline-width: 0px;
}
.submitButton {
  width: 100%;
  height: 51px;
  border-radius: 0px 0px 15px 15px;
  background-color: white;
  border-width: 2px;
  border-color: white;
  border-style: solid;
  color: rgba(6, 5, 6, 1);
  font-size: 15px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
}
.submitButton:hover {
  background-color: #8858ed;
  color: white;
}

/* contact us form */
.contactForm {
  width: 580px;
  padding-bottom: 120px;
}
.forminput {
  height: 51px;
  width: 100%;  
  background-color: transparent;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
  font-size: 16px;
  color: white;
  line-height: 22.4px;
  padding: 3px;
  text-align: center;
  border-width: 0px;
  border-width: 2px;
  border-style: solid;
  border-color: #8858ed;
}
.forminput:focus {
  border-color: white;
  border-width: 2px;
  border-style: solid;
  outline-width: 0px;
}
.leftinput {
  border-radius: 15px 0px 0px 0px;
}
.rightinput {
  border-radius: 0px 15px 0px 0px;
}
.bottominput {
  border-radius: 0px 0px 15px 15px;
}
.contactSubmit {width: 100%;
  height: 48px;
  background-color: #8858ed;
  border-width: 0px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
}
.contactSubmit:hover {
  background-color: white;
  color: #8858ed;
}

/* Product */
.productTitle {
  font-size: 70px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
  margin-top: 100px;
  margin-bottom: 30px;
}

.filterPart {
  width: 200px;
  /* flex-shrink: 0; */
  /* flex-grow: 1; */
}
.filterItem {
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 400;
  font-family: roboto-thin, roboto, sans-serif;
  width: 100%;
  border-bottom-color: rgba(255, 255, 255, 0.2);
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.filterTitle {
  font-size: 26px;
}
.filterlistTitle {
  color: white;
  font-size: 16px;
  font-family: roboto-thin, roboto, sans-serif;
}
.filterItem ul {
  padding-left: 0;
}
.filterlist li {
  list-style-type: none;
  padding: 3px 0px;
}
.filterlist a {
  color: white;
  font-size: 14px;
  font-family: roboto-thin, roboto, sans-serif;
}
.filterlist a:hover {
  color: rgba(136, 88, 237, 0.8);
}

.listPart {
  padding-left: 30px;
  flex-grow: 1;
  /* padding-right: 40px; */
}
.sortDiv {
  padding-bottom: 40px;
}

.paginationDiv {
  align-self: center;
  padding-top: 30px;
  padding-bottom: 40px;
}

.preNextButton {
  font-size: 16px;
  padding: 0px 12px;
}
.disable {
  color: #aaaaaa;
}

.share_button {
  margin-right: 20px;
}

.formLabel {
  font-size: 16px;
  /* width: 283px; */
  width: 100%;
  text-align: left;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
  color: white;
  margin-bottom: 10px;
}
.formInput {
  height: 24px;
  width: 100%;
  padding: 2px;
  background-color: transparent;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
  font-size: 16px;
  color: white;
  line-height: 22.4px;
  border-bottom: 1px solid white;
  border-top:1px solid black;
  border-left:1px solid black;
  border-right:1px solid black;
}
.formInput:focus {
  background-color: transparent;
  border-bottom-width: 1px;
  border-top:1px solid black;
  border-left:1px solid black;
  border-right:1px solid black;
  border-bottom-color: rgba(136, 88, 237, 1);
  border-bottom-style: solid;
  outline-width: 0px;
}

.formInput:-internal-autofill-previewed,
.formInput:-internal-autofill-selected {
  -webkit-text-fill-color: #807c7c;
  background-color: transparent;
  transition: background-color 5000s ease-out 0.5s;
}

.formSubmitButton {
  width: 100%;
  height: 48px;
  background-color: #8858ed;
  border-width: 0px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: "montserrat", sans-serif;
}
.formSubmitButton:hover {
  background-color: white;
  color: #8858ed;
}

/* .overlayDrawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.8);
} */
.overlayDrawer {
  width: 350px;
  height: 100%;
  background: rgba(0,0,0,.8);
  margin: 0px auto;
  position: absolute;	
  overflow: hidden;	/* 溢出隐藏 */
  top: 0;
  right: 0;
  z-index: 100;
  /* bottom: 0; */
}

.pac-target-input {
  /* height: 24px; */
  width: 100%;
  padding: 4px 11px;
  background-color: transparent;
  font-family: proxima-n-w01-reg, proxima-n-w05-reg, sans-serif;
  font-size: 14px;
  color: white;
  line-height: 22.4px;
  border-color: #8858ED;
  border-style: solid;
  /* border-bottom: 1px solid white;
  border-top:1px solid black;
  border-left:1px solid black;
  border-right:1px solid black; */
}

.site-navigation-steps {
  margin-bottom: 60px;
  box-shadow: 0px -2px 0 0 #666666 inset;
}

.steps-action {
  width: 80%;  
  margin: 0 auto;
  text-align: right;
}

/* .pac-target-input:focus {
  background-color: transparent;
  border-bottom-width: 1px;
  border-top:1px solid black;
  border-left:1px solid black;
  border-right:1px solid black;
  border-bottom-color: rgba(136, 88, 237, 1);
  border-bottom-style: solid;
  outline-width: 0px;
} */

/* .part2{
  background-color: rgba(0,0,0,1);
}
.part3{
  background-color: rgba(77,77,77, 0.4);
} */

/* .homeContent {
  width: 100%;
  display: grid;
  position: static;
  height: auto;
  min-height: 40px;
  grid-template-rows: repeat(2, min-content) 1fr;
  grid-template-columns: 100%;
} */

.blackRow {
  background-color: black;
}
.grayRow {
  background-color: rgba(77, 77, 77, 0.4);
}
.normalRow {
  background-color: black;
  width: 100%;
  margin: 0 auto;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 30px;
}

.overlays {
  height: auto;
  width: 100%;
  display: grid;
  position: static;
  grid-template-rows: 1fr;
  grid-template-columns: 100%;
  margin-bottom: -73px;
}

/* .site-layout-content {
  min-height: 280px;  
  }
   */
/* .ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
[data-theme="dark"] .site-layout-content {
  background: #141414;
} */

.testColor {
  color: #fff;
  color: #cccccc;
  color: #aaaaaa;
  color: #666666;
  color: #d28435;
  color: #ea8015;
  color: #d9b310;
  color: #037b9d;
  color: #328cc1;
  color: #0b3c5d;
  color: #1d2731;

  color: #70ca63;
  color: #e9573f;
  color: #70ca63;
  color: #3b3f4f;
  color: #f6bb42;
  color: #3bafda;
  color: #57c7d4;
  color: #926dde;
  color: #7d5b4f;
  color: #f44c87;
  color: #37bc9b;
  color: #184e74;
  color: #c1c286;
  color: #aa2906;
  color: #bc94ac;
}
