body {
  margin: 0;
  font-family:  -apple-system, BlinkMacSystemFont, "Helvetica Neue","STHeiti Light",STHeiti,"微软雅黑体","Microsoft YaHei","微软正黑体",
  "Microsoft JhengHei","Arial Unicode MS",Roboto,sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: black; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 居中屏幕 */
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



